<template>
  <b-sidebar-page :mobileMaxWidth="992" class="h-100">
    <template v-slot:sidebar="{ isMobile }">
      <div class="sidebar">
        <div class="sidebar-content text-white p-3">
          <div v-if="isMobile" class="mt-3"></div>
          <router-link v-if="!isMobile" to="/dashboard">
            <img src="/img/logo_bike_branco.svg" width="80%" />
          </router-link>
          <hr v-if="!isMobile" />

          <div v-b-toggle.sidebar-group-usuario class="nav-link pointer mt-1">
            <span>{{ nomeUsuario }}</span>
            <small class="collapse-flip-icon ml-2">
              <i class="fas fa-caret-down"></i>
            </small>
          </div>
          <b-collapse id="sidebar-group-usuario">
            <b-nav vertical class="text-left">
              <b-nav-item @click="onClickLogout">
                <i class="fas fa-sign-out-alt mr-3"></i>
                <span>Sair</span>
              </b-nav-item>
            </b-nav>
          </b-collapse>

          <hr />
          <b-nav vertical class="text-left">
            <b-nav-item to="/dashboard/novo-seguro" replace>
              <i class="fas fa-plus-square mr-4"></i>
              <span>Novo seguro</span>
            </b-nav-item>
            <b-nav-item to="/dashboard/seguros">
              <i class="fas fa-list mr-4"></i>
              <span>Seguros</span>
            </b-nav-item>
            <b-nav-item to="/dashboard/link-parceiro">
              <i class="fas fa-link mr-4"></i>
              <span>Indique amigos</span>
            </b-nav-item>
            <b-nav-item to="/dashboard/dados-bancarios">
              <i class="fas fa-wallet mr-4"></i>
              <span>Dados bancários</span>
            </b-nav-item>
            <b-nav-item
              target="_blank"
              :href="
                `https://api.whatsapp.com/send?phone=${config.WHATSAPP_NUMERO}&text=${config.WHATSAPP_MENSAGEM_INICIAL}`
              "
            >
              <i class="fab fa-whatsapp mr-4"></i>
              <span>Fale com a gente</span>
            </b-nav-item>
          </b-nav>
        </div>
      </div>
    </template>
    <template v-slot:content="{ toggleSideBar, isMobile }">
      <div class="h-100">
        <div style="min-height:62px;">
          <b-navbar type="dark" class="px-3" v-if="isMobile" toggleable="lg">
            <div class="row no-gutters w-100 align-items-center">
              <button @click="toggleSideBar" class="navbar-toggler p-1">
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="col text-center">
                <router-link to="/">
                  <img src="/img/logo_bike.svg" width="180" />
                </router-link>
              </div>

              <div style="width:40px;">&nbsp;</div>
            </div>
          </b-navbar>
          <div class="top-line"></div>
        </div>

        <div class="dasboard-view-container">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </template>
  </b-sidebar-page>
</template>

<script>
import BSidebarPage from "./BSidebarPage";
import auth from "../auth";
import config from "../config";

export default {
  components: {
    BSidebarPage,
  },
  data() {
    return {
      config,
      nomeUsuario: undefined,
      fotoUsuario: undefined,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (!userInfo.id) return this.$router.replace("/parceiro");

    this.nomeUsuario = auth.getUserName();
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("hide-external-buttons");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("hide-external-buttons");
    next();
  },
  methods: {
    onClickLogout() {
      auth.logout();
      this.$router.replace("/parceiro");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variaveis";

.sidebar {
  background: @dark-primary-color;
  height: 100vh;
  position: relative;
}

.sidebar::before {
  content: "";
  background-image: url("/img/sidebar-bg.jpg");
  background-size: cover;
  background-position: center center;
  opacity: 0.0618;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.sidebar-content {
  position: absolute;
  text-align: center;
}

.sidebar-content .nav-link,
.sidebar-content .nav-link:visited {
  color: white;
  margin-bottom: 16px;
}

.sidebar-content .fas {
  font-size: 1.2em;
}

.sidebar-content .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: @border-radius-small;
}

.sidebar hr {
  // width: 90%;
  border-top: 1px solid rgba(180, 180, 180, 0.3);
}

nav {
  background-color: @dark-primary-color;
}

.top-line {
  width: 100%;
  height: 4px;
  background: linear-gradient(
    90deg,
    @dark-primary-color 0%,
    @primary-color 50%,
    white 100%
  );
}

.dasboard-view-container {
  min-height: calc(100% - 62px);
}
</style>
